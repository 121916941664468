/* General landing page styles */
.landing-page {
  text-align: center;
  padding: 50px;
  font-family: 'Poppins', sans-serif;
}

.bulleted-list {
  list-style-type: disc; /* Ensures bullet points are shown */
  padding-left: 20px; /* Indent the list */
  text-align: left; /* Align text to the left */
}

/* Title container */
.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

/* Title styling */
h1 {
  font-size: 60px; /* Increased font size */
  font-weight: bold;
  color: #ff4500;
  margin-right: 20px;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}

/* Dog Head Image Styling */
.dog-head-image {
  width: 200px; /* Adjust the size to make it larger */
  height: auto;
}

/* Auth buttons */
.auth-buttons {
  margin-top: 20px;
}

.login-button,
.signup-button {
  padding: 10px 20px;
  margin: 10px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #ff4500;
  color: white;
  text-decoration: none;
}

.login-button:hover,
.signup-button:hover {
  background-color: #ff4500;
}

/* Preview section */
.preview {
  margin-top: 40px;
}

.preview-images {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.preview-image {
  width: 200px;
  cursor: pointer;
  border: 2px solid #ff4500;
  border-radius: 5px;
}

.preview-image:hover {
  opacity: 0.8;
}

/* Modal styles */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}
