.my-dogs-container {
  padding: 20px;
  background-color: #f9f9f9;
}

.my-dogs-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.my-dogs-title {
  font-size: 30px;
  font-weight: bold;
  color: #141414;
}

.add-dog-button {
  background-color: #ff4500;
  color: white;
  padding: 10px 20px;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, box-shadow 0.3s;
}

.add-dog-button:hover {
  background-color: #ff4500;
}

.dog-count-box {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.dog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.dog-card {
  position: relative;
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.dog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.dog-card-overlay {
  position: absolute;
  top: 10px;
  left: 10px;
  opacity: 0;
  transition: opacity 0.3s;
}

.dog-card:hover .dog-card-overlay {
  opacity: 1;
}

.delete-icon {
  background-color: #ff4500;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.delete-icon:hover {
  background-color: #ff4500;
}

.dog-link {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dog-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin-bottom: 10px;
}

.dog-info {
  text-align: center;
}

.dog-name {
  font-size: 18px;
  font-weight: bold;
  color: #141414;
  margin-bottom: 5px;
}

.dog-breed {
  font-size: 14px;
  color: #888;
}

.owner-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 18px;
  color: #ff4500;
}
