.add-dog-container {
  /* Remove text-align to avoid centering and control container width */
  position: relative;
  padding: 20px;
  max-width: 600px; /* Control the width for a compact form */
  margin-left: 0; /* Align the form to the left */
}

.form-row {
  margin-bottom: 10px; /* Reduce space between fields */
}

.submit-button-container {
  text-align: right;
  margin-bottom: 20px;
}

.submit-button {
  padding: 10px 20px;
  background-color: #ff4500 !important;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.submit-button:hover {
  background-color: #ff4500 !important;
}

.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.label {
  display: block;
  font-size: 1rem;
  font-weight: 500;
  color: #333; /* Ensure label is visible in light mode */
  margin-bottom: 5px;
}
